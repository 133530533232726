import { createAction } from '@reduxjs/toolkit'

export const setVehicleForm = createAction('CARRIER_SET_VEHICLE_FORM', 
    (vehicleForm)=> ({
        payload: vehicleForm
    })
)

export const resetVehicleForm = createAction('CARRIER_RESET_VEHICLE_FORM')

export const setMyVehicles = createAction('CARRIER_SET_MY_VEHICLES', 
    (myVehicles) => ({
        payload: myVehicles
    })
)

