import { createAction } from '@reduxjs/toolkit'

export const openLoading = createAction('PAGE_OPEN_LOADING',
    () => ({
        payload: true
    })
)

export const dismissLoading = createAction('PAGE_DISMISS_LOADING',
    () => ({
        payload: false
    })
)

export const showAlertModal = createAction('SHOW_ALERT_MODAL',
    (alert) => ({
        payload: alert
    })
)

export const closeAlertModal = createAction('CLOSE_ALERT_MODAL')

export const setVehicleTypesList = createAction('PAGE_SET_VEHICLE_TYPES_LIST', (vehicles) => ({payload: vehicles}))