import { createAction } from '@reduxjs/toolkit'

export const setInstantSearchLocations = createAction('CARRIER_SET_INSANT_SEARCH_LOCATIONS',
    (instantSearchLocations) => ({
        payload: instantSearchLocations
    })
)

export const setSearchShipmentResults = createAction('CARRIER_SET_SEARCH_SHIPMENT_RESULTS',
    (searchResults) => ({
        payload: searchResults
    })
)

// export const setBookingStep = createAction('SHIPPER_SET_BOOKING_STEP', 
//     (step)=> ({
//         payload: step
//     })
// )


