import { Spin, } from 'antd'
import { ReactComponent as KargaXLogo } from 'assets/images/kargax-logo-blue.svg'

const AppSuspenseLoader = () => (
    <div
        className="container mx-auto h-screen"
    >
        <div className="flex flex-col justify-center items-center h-full">
            <KargaXLogo width="300" />
            <Spin size="large" className="mt-10" />
        </div>
    </div>
)

export default AppSuspenseLoader