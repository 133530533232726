export const BOOKING_STEPS = {
  INPUT_PLACES: 0,
  PACKAGE_DETAILS: 1,
  SCHEDULE: 2,
  REVIEW: 3,
};

export const CONTAINTER_LOAD_TYPE = {
  FTL: "FTL - Full Truck Load",
  LTL: "LTL- Less than Truck Load",
};

export const CARGO_TYPE = {
  "general-cargo": "General Cargo",
  "special-cargo-perishable": "Special Cargo (Perishable Goods)",
  "special-cargo-valuables": "Special Cargo (Valuable Goods)",
};

export const WINDOW_TIME = {
  0: "Business hours (9:00 am - 6:00 pm)",
  1: "Other time",
};

export const VEHICLE_TYPE = {
  "l300-van": "FB Type",
  "8w-wing-van": "8W Wingvan",
  "10w-wing-van": "10W Wingvan",
  "12w-wing-van": "12W Wingvan",
  "6w-fwd-truck": "6W FWD Truck",
  "6w-fwd-wing-van": "6W FWD Wingvan",
  "4w-closed-van": "4W Closedvan",
  "6w-closed-van": "6W Closedvan",
  "6w-dropside": "6W Dropside",
  "10w-dropside": "10W Dropside",
  // "long-pickup-truck": "Long Pickup Truck",
  // "pickup-truck": "Pickup Truck",
  "6w-boom-truck": "6W Boom Truck",
  "8w-boom-truck": "8W Boom Truck",
  "10w-boom-truck": "10W Boom Truck",
  "reefer-truck": "Reefer Truck",
  "containerized-10ft": "Containerized 10ft",
  "containerized-20ft": "Containerized 20ft",
  "containerized-40ft": "Containerized 40ft",
};

export const SHIPMENT_STATUS_FILTERS = [
  {
    name: "All",
    value: null,
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Confirmed",
    value: ["scheduled", "at-pickup-point", "in-transit", "delivered"],
  },
  {
    name: "History",
    value: ["completed", "cancelled"],
  },
];

export const SHIPPER_SHIPMENT_STATUS_SPIEL = {
  'pending': 'We are currently looking for potential carrier to this booking.',
  'completed': 'Congratulations, this shipment has been completed.',
  'scheduled': 'Your shipment has been scheduled.',
  'cancelled': 'Oops! This booking was cancelled.'
}

export const CARRIER_SHIPMENT_STATUS_SPIEL = {
  pending: "If you’re a good match, shipper will contact you shortly.",
};

export const VEHICLE_STATUS_FILTERS = [
  {
    name: "Active",
    value: "active",
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Inactive",
    value: "inactive",
  },
];

export const DRIVER_STATUS_FILTERS = [
  {
    name: "All",
    value: "active",
  },
  {
    name: "Inactive",
    value: "inactive",
  },
];

export const FLEET_DETAILS_TABS = [
  {
    name: "My Vehicles",
    value:"vehicle"
  },
  {
    name: "My Drivers",
    value:"driver"
  }
]
