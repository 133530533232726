import { Modal, } from 'antd'
import classNames from 'classnames'
import AppButton from 'components/AppButton'
import { connect, useDispatch } from 'react-redux'
import { closeAlertModal } from 'store/actions/page-actions'

const mapStateToProps = ({ page }) => ({ ...page })

const GlobalAlert = connect(mapStateToProps)(
    ({ alert }) => {
        const dispatch = useDispatch()
        return (
            <Modal
                visible={alert?.open}
                footer={null}
                closable={false}
                width={500}
                zIndex={9999}

            >
                <div className="flex flex-col space-y-8 items-center p-4">
                    {
                        alert.title &&
                        <div className="text-2xl font-bold">
                            {alert.title}
                        </div>
                    }
                    {
                        alert.title &&
                        <div className="text-lg">
                            {alert.message}
                        </div>
                    }
                    {
                        alert.showButtons &&
                        <div className="flex flex-col-reverse mt-4 w-full">

                            <AppButton
                                className={classNames('w-full', alert.cancelButtonClassNames)}
                                block
                                size="large"
                                onClick={() => {
                                    dispatch(closeAlertModal())
                                    if (alert.onActionResult) {
                                        alert.onActionResult(false)
                                    }
                                }}
                            >
                                {
                                    alert.cancelButtonLabel || 'Cancel'
                                }
                            </AppButton>
                            <AppButton
                                className={classNames('w-full mb-4', alert.okButtonClassNames)}
                                block
                                size="large"
                                onClick={() => {
                                    dispatch(closeAlertModal())
                                    if (alert.onActionResult) {
                                        alert.onActionResult(true)
                                    }
                                }}
                            >
                                {
                                    alert.okButtonLabel
                                }
                            </AppButton>
                        </div>
                    }
                </div>
                {

                }
            </Modal>
        )
    }
)

export default GlobalAlert