import { Modal, Spin } from 'antd'
import { connect } from 'react-redux'

const mapStateToProps = ({ page }) => ({...page})

const GlobalLoading = connect(mapStateToProps)( 
    ({showLoading}) => (
        <Modal
            visible={showLoading}
            footer={null}
            closable={false}
            width={83}
            centered
            zIndex={10000}
        >
            <Spin
                size="large"
            />
        </Modal>
    )
)

export default GlobalLoading