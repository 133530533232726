import { createAction } from '@reduxjs/toolkit'

export const setNotificationList = createAction('NOTIF_SET_NOTIF_LIST',
    (list) => ({
        payload: list
    })
)

export const setNotificationUnopenedCount = createAction('NOTIF_SET_UNOPENED_COUNT',
    (count) => ({
        payload: count
    })
)


// export const setBookingStep = createAction('SHIPPER_SET_BOOKING_STEP', 
//     (step)=> ({
//         payload: step
//     })
// )


