import { createReducer } from '@reduxjs/toolkit'

import * as NotificationActions from '../actions/notification-actions'
import * as UserActions from 'store/actions/user-actions'


const initialState = {
    notificationList: [],
    unOpenedCount: 0
}

const notificationReducer = createReducer(initialState, (builder) => {
    builder.addCase(NotificationActions.setNotificationList, (state, action) => ({
        ...state,
        notificationList: action.payload
    }))
    builder.addCase(NotificationActions.setNotificationUnopenedCount, (state,action) => ({
        ...state,
        unOpenedCount: action.payload
    }))
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
        ...initialState
     }))
})

export default notificationReducer