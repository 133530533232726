import { createAction } from '@reduxjs/toolkit'

export const setCurrentUserProfile = createAction('SET_CURRENT_USER_PROFILE', 
    (userProfile)=> ({
        payload: userProfile
    })
)

export const setUserIsLoggedIn = createAction('SET_USER_IS_LOGGED_IN', 
    (isLoggedIn)=> ({
        payload: isLoggedIn
    })
)

export const setUserLogOut = createAction('SET_USER_LOGOUT', 
    (isLoggedIn)=> ({
        payload: true
    })
)
