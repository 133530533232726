import { createReducer } from '@reduxjs/toolkit'
import * as CarrierVehicleAction from 'store/actions/carrier-vehicles-action'
 
const initialState = {
    vehicleForm: null,
    myVehicles: []
}

const carrierVehicleReducer = createReducer(initialState, (builder) => {
    builder.addCase(CarrierVehicleAction.setVehicleForm, (state, action) => ({
        ...state,
        vehicleForm: action.payload
    }))
    builder.addCase(CarrierVehicleAction.resetVehicleForm, (state, action) => ({
        ...state,
        vehicleForm: initialState.vehicleForm
    }))
    builder.addCase(CarrierVehicleAction.setMyVehicles, (state, action) => ({
        ...state,
        myVehicles: action.payload
    }))
})

export default carrierVehicleReducer