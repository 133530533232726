import { createReducer } from '@reduxjs/toolkit'

import * as PageActions from '../actions/page-actions'
import * as UserActions from 'store/actions/user-actions'


const initialState = {
    showLoading: false,
    alert: {
        open: false,
        title: null,
        message: null,
        okButtonLabel: 'Okay',
        onActionResult: null,
        okButtonClassNames: null,
        cancelButttonClassNames: null,
        cancelButtonLabel: 'Not Now',
        showButtons: true
    },
    vehicleTypesList: [],
}

const pageReducer = createReducer(initialState, (builder) => {
    builder.addCase(PageActions.openLoading, (state, action) => ({
        ...state,
        showLoading: action.payload
    }))
    builder.addCase(PageActions.dismissLoading, (state, action) => ({
        ...state,
        showLoading: action.payload
    }))
    builder.addCase(PageActions.showAlertModal, (state, action) => ({
        ...state,
        alert: {
            ...initialState.alert,
            open: true,
            ...action.payload
        } 
    }))
    builder.addCase(PageActions.closeAlertModal, (state, action) => ({
        ...state,
        alert: initialState.alert
    }))
    builder.addCase(PageActions.setVehicleTypesList, (state, action) => ({
        ...state,
        vehicleTypesList: action.payload
    }))
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
        ...initialState
     }))
})

export default pageReducer