import { createReducer } from '@reduxjs/toolkit'
import * as ShipperChatActions from 'store/actions/shipment-chat-actions'
import * as UserActions from 'store/actions/user-actions'

const initialState = {
    selectedShipment: null,
    transitLogs: null,
    nextMovement: null
}

const shipperChatReducer = createReducer(initialState, (builder) => {
    builder.addCase(ShipperChatActions.setSelectedShipment, (state, action) => ({
        ...state,
        selectedShipment: action.payload
    }))
    builder.addCase(ShipperChatActions.setShipmentTransitLogs,
        (state, action) => ({
            ...state,
            transitLogs: action.payload
    }))
    builder.addCase(ShipperChatActions.setShipmentNextMovement,
        (state, action) => ({
            ...state,
            nextMovement: action.payload
        })
    )
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
        ...initialState
     }))
})

export default shipperChatReducer