import { createReducer } from '@reduxjs/toolkit'
import * as CarrierShipmentActions from 'store/actions/carrier-shipment-actions'
import * as UserActions from 'store/actions/user-actions'

const initialState = {
    instantSearchLocations: null,
    searchResults: []
}

const carrierShipmentReducer = createReducer(initialState, (builder) => {
    builder.addCase(CarrierShipmentActions.setInstantSearchLocations, (state, action) => ({
        ...state,
        instantSearchLocations: action.payload
    }))
    builder.addCase(CarrierShipmentActions.setSearchShipmentResults, (state, action) => ({
        ...state,
        searchResults: action.payload
    }))
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
        ...initialState
     }))
    
})

export default carrierShipmentReducer