import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import HttpClient from "services/http-client"
import { setVehicleTypesList } from "store/actions/page-actions"

const useVehicleTypes = () => {
    const { vehicleTypesList } = useSelector(({ page }) => ({ ...page }))
    const dispatch = useDispatch()

    useEffect(() => {
        if (!vehicleTypesList || vehicleTypesList.length === 0) {
            HttpClient.get('common/vehicle-types', { data: null, noError: true })
                .then(({ data }) => {
                    let vehicles = {}
                    data.data.map(({ slug, ...otherProps }) => {
                        vehicles[slug] = {
                            slug, ...otherProps,
                        }
                    })
                    dispatch(setVehicleTypesList(
                        vehicles
                    )
                    )
                })
        }
    }, [vehicleTypesList])
}

export default useVehicleTypes