import axios from "axios";
import { get } from "lodash";

const HttpClient = axios.create({
  baseURL: "/api/v1/",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

HttpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
});

const responseErrorInterceptor = (error) => {
  let { response } = error;
  error.message = get(
    response,
    "data.message",
    "Sorry, we encountered an error while processing your request. Please try again later."
  );

  if (!error.config.noError) {
    if (typeof onstorage === "function") {
      onstorage({
        key: "error",
        oldValue: "",
        newValue: error.message,
      });
    }
  }
  return Promise.reject(error);
};

HttpClient.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default HttpClient;
