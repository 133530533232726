import { createReducer } from '@reduxjs/toolkit'
import * as ShipperShipmentActions from 'store/actions/shipper-shipment-actions'
import * as UserActions from 'store/actions/user-actions'

import { BOOKING_STEPS } from 'services/constants'
 
const initialState = {
    shipmentForm: { fcl_import: {}, fcl_export: {}, },
    bookingStep: BOOKING_STEPS.INPUT_PLACES,
    shipmentRoutes: [],
    myShipments: []
}

const shipperShipmentReducer = createReducer(initialState, (builder) => {
    builder.addCase(ShipperShipmentActions.setShipmentForm, (state, action) => ({
        ...state,
        shipmentForm: action.payload
    }))
    builder.addCase(ShipperShipmentActions.setBookingStep, (state, action) => ({
        ...state,
        bookingStep: action.payload
    }))
    builder.addCase(ShipperShipmentActions.resetShipmentForm, (state, action) => ({
        ...state,
        shipmentForm: initialState.shipmentForm,
    }))
    builder.addCase(ShipperShipmentActions.setMyShipments, (state, action) => ({
        ...state,
        myShipments: action.payload
    }))
    builder.addCase(ShipperShipmentActions.setShipmentRoutes, (state, action) => ({
        ...state,
        shipmentRoutes: action.payload
    }))
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
       ...initialState
    }))
})

export default shipperShipmentReducer