import { Button, } from 'antd'
import classname from 'classnames'

const AppButton = ({ children, className, style, active, type, ...props }) => (
    <Button

        {...props}
        style={{
            ...style,
        }}
        type={type}
        className={classname('hover:bg-opacity-70', {
            // 'rounded-none text-primary-client': active,
            'rounded-md text-white': !active,
            'bg-secondary-red text-white border-secondary-red': type === 'secondary',
            'bg-secondary-blue border-secondary-blue': type === 'primary',
            'rounded-none border-0 border-b-2 text-primary-client border-primary-client ': type === 'text' && active,
            'bg-primary-client text-white border-primary-client': type === 'primary-client',
            'bg-primary-trucker text-secondary-blue border-primary-trucker': type === 'primary-trucker',
            'bg-white text-primary-client border-primary-client border-2 border-solid  hover:bg-opacity-80': type === 'secondary-client',
            'bg-white text-secondary-blue border-primary-trucker border-2 border-solid hover:bg-opacity-80': type === 'secondary-trucker',
            'bg-tertiary-blue text-white': type === 'default'
        }, className)
        }

    >
        {children}
    </Button>
)

export default AppButton