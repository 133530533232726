import { createAction } from '@reduxjs/toolkit'

export const setSelectedShipment = createAction('SHIPPER_SET_SHIPMENT_CHAT', 
    (shipment)=> ({
        payload: shipment
    })
)

export const setShipmentTransitLogs = createAction('SHIPPER_SET_SHIPMENT_TRANSIT_LOGS',
    (transitlogs) => ({
        payload: transitlogs
    })
)


export const setShipmentNextMovement = createAction('SHIPPER_SET_SHIPMENT_NEXT_MOVEMENT',
    (nextMovement) => ({
        payload: nextMovement
    })
)