import React, { useEffect } from 'react';
import AppSuspenseLoader from 'components/AppSuspenseLoader'
import ScrollToTop from 'components/ScrollToTop'
import GlobalLoading from 'views/widgets/GlobalLoading'
import GlobalAlert from './widgets/GlobalAlert';
import GlobalErrorListener from './widgets/GlobalErrorListener';
import useVehicleTypes from './useVehicleTypes';

const Carrier = React.lazy(() => import('views/carrier'));
const Shipper = React.lazy(() => import('views/shipper'));


function App() {
	useVehicleTypes()
	return (
		<React.Suspense fallback={<AppSuspenseLoader />}>
			<GlobalLoading />
			<GlobalAlert />
			<GlobalErrorListener />
			<ScrollToTop>
				<Carrier />
				<Shipper />
			</ScrollToTop>
			
		</React.Suspense>
	);
}

export default App;
