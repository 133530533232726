import { configureStore } from '@reduxjs/toolkit'

import usersReducer from './user-reducer'
import shipperShipmentReducer from './shipper-shipment-reducer'
import carrierShipmentReducer from './carrier-shipment-reducer'
import shipperChatReducer from './shipper-chat-reducer'
import pageReducer from './page-reducer'
import carrierVehicleReducer from './carrier-vehicles-reducer'
import carrierDriverReducer from './carrier-driver-reducer'
import notificationReducer from './notification-reducer'
// ...

export const store = configureStore({
  reducer: {
    users: usersReducer,
    shipperShipment: shipperShipmentReducer,
    carrierShipment: carrierShipmentReducer,
    shipmentChat: shipperChatReducer,
    page: pageReducer,
    carrierVehicle: carrierVehicleReducer,
    carrierDriver: carrierDriverReducer,
    notifications: notificationReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch