import { createAction } from '@reduxjs/toolkit'

export const setDriverForm = createAction('CARRIER_SET_DRIVER_FORM', 
    (driverForm)=> ({
        payload: driverForm
    })
)

export const resetDriverForm = createAction('CARRIER_RESET_DRIVER_FORM')

export const setMyDrivers = createAction('CARRIER_SET_MY_DRIVERS', 
    (myDrivers) => ({
        payload: myDrivers
    })
)

