import { createAction } from '@reduxjs/toolkit'

export const setShipmentForm = createAction('SHIPPER_SET_SHIPMENT_FORM', 
    (shipmentForm)=> ({
        payload: shipmentForm
    })
)

export const resetShipmentForm = createAction('SHIPPER_RESET_SHIPMENT_FORM')

export const setBookingStep = createAction('SHIPPER_SET_BOOKING_STEP', 
    (step)=> ({
        payload: step
    })
)

export const setMyShipments = createAction('SHIPPER_SET_MY_SHIPMENTS', 
    (shipments) => ({
        payload: shipments
    })
)


export const setShipmentRoutes = createAction('SHIPPER_SET_SHIPMENT_ROUTES',
    (routes) => ({
        payload: routes
    })
)
