import { createReducer } from '@reduxjs/toolkit'
import * as CarrierDriverAction from 'store/actions/carrier-driver-action'
 
const initialState = {
    driverForm: null,
    myDrivers: []
}

const carrierDriverReducer = createReducer(initialState, (builder) => {
    builder.addCase(CarrierDriverAction.setDriverForm, (state, action) => ({
        ...state,
        driverForm: action.payload
    }))
    builder.addCase(CarrierDriverAction.resetDriverForm, (state) => ({
        ...state,
        driverForm: initialState.driverForm
    }))
    builder.addCase(CarrierDriverAction.setMyDrivers, (state, action) => ({
        ...state,
        myDrivers: action.payload
    }))
})

export default carrierDriverReducer