import { createReducer } from '@reduxjs/toolkit'
import * as UserActions from 'store/actions/user-actions'

const initialState = {
    userProfile: null,
    isLoggedIn: false,
}

const userReducer = createReducer(initialState, (builder) => {
    builder.addCase(UserActions.setCurrentUserProfile, (state, action) => ({
        ...state,
        userProfile: action.payload
    }))
    builder.addCase(UserActions.setUserIsLoggedIn, (state, action) => ({
        ...state,
        isLoggedIn: action.payload
    }))
    builder.addCase(UserActions.setUserLogOut, (state, action) => ({
        ...initialState
    }))
})

export default userReducer